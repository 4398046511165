import React, { useContext, useLayoutEffect } from 'react'
// import UserAuthContext from '../../contexts/user_auth_context'
import EmptyLayout from '../../layouts/empty_layout'
import { Link } from 'react-router-dom';

export const OAuth2_Callback = (props: any) => {
  // const userContext = useContext(UserAuthContext);

  // useLayoutEffect(() => {
  //   userContext.isLoggedIn = true
  // });

  return (
    <EmptyLayout>
      <div>Login Callback Page</div>
      <Link to="/">Home</Link>
    </EmptyLayout>
  )
}