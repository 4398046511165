import * as React from 'react'

import EmptyLayout from '../../layouts/empty_layout'

import './login_page.scss';

export const LoginPage = (props: any) => {
  return (
    <EmptyLayout>
    </EmptyLayout>
  )
}