import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, RouteProps, Redirect } from 'react-router-dom';

import UserAuthContext, { defaultUserAuthContext } from './contexts/user_auth_context'

import MainPage from './pages/main'
import LoginPage from './pages/login'
import OAuth2_Callback from './pages/oauth2_callback'

import './App.css';

// Page management
const AppRoute = ({ page: Page, secure: secure, ...rest }: RouteProps & { page: any, secure?: boolean }) => {
  const userContext = useContext(UserAuthContext);
  if (secure) {
    if (userContext.isLoggedIn) {
      return (
        <Route {...rest} render={props => (<Page {...props} />)} />
      )
    }
    else {
      return (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  }

  return (
    <Route {...rest} render={props => (<Page {...props} />)} />
  )
}

function App() {
  return (
    <UserAuthContext.Provider value={defaultUserAuthContext}>
      <BrowserRouter>
        <Switch>
          <AppRoute exact path='/' secure={true} page={MainPage} />
          <AppRoute exact path='/login' page={LoginPage} />
          <AppRoute exact path='/login/oauth2_callback' page={OAuth2_Callback} />
        </Switch>
      </BrowserRouter>
    </UserAuthContext.Provider >
  );
}

export default App;
