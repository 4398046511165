import * as React from 'react'

const DashboardLayout = (props: any) => {
  return (
    <div>
      Dashboard Layout
      {props.children}
    </div>
  )
}
export default DashboardLayout;