import * as React from 'react'

const EmptyLayout = (props: any) => {
  return (
    <div className="container-fluid">
      {props.children}
    </div>
  )
}
export default EmptyLayout;
